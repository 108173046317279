import React from 'react';
import { WeekendTrip } from '../components/page-wrappers';
import { Helmet } from 'react-helmet';

// tslint:disable no-default-export
export default () => {
  return (
    <>
      <Helmet title="Valle de Guadalupe Weekend Tour">
        <link
          rel="canonical"
          href="https://www.valledeguadalupewinetours.com/valle-de-guadalupe-weekend-tour"
        />
      </Helmet>
      <WeekendTrip />
    </>
  );
};
